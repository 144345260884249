// src/sagas/userSaga.js
import { takeLatest, call, put } from "redux-saga/effects";
import {
  fetchUserSuccess,
  fetchUserFailure,
} from "./../actions/common";
import { fetchUserFromAPI } from "./../api/common";
import { FETCH_USER } from "../actionTypes/common";

function* fetchUserSaga() {
    console.log('check123')
  try {
    const user = yield call(fetchUserFromAPI);
    console.log('check123', user)
    yield put(fetchUserSuccess(user.todos));
  } catch (error) {
    console.log('checkerror', error)
    yield put(fetchUserFailure(error));
  }
}

export default function* commonSaga() {
  yield takeLatest(FETCH_USER, fetchUserSaga);
}
