// src/App.js
import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchUser } from "./redux/actions/common";
import AppRoutes from "./Routes"; // Adjust the path if necessary
import "@fontsource/readex-pro"; // Defaults to weight 400
import "@fontsource/readex-pro/500.css"; // Weight 500
import "@fontsource/readex-pro/700.css"; // Weight 700
import { BrowserRouter as Router } from "react-router-dom";

function App() {
  const dispatch = useDispatch();
  const { loading, user, error } = useSelector((state) => state.common);
  console.log("loading", loading, user, error);
  
  useEffect(() => {
    dispatch(fetchUser()); // Your existing code
    
    // Initialize Google Analytics
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-JQ17N2B4P6";
    document.head.appendChild(script);
    
    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      window.gtag = gtag;
      
      gtag("js", new Date());
      gtag("config", "G-JQ17N2B4P6", {
        page_path: window.location.pathname,
      });
    };
  }, [dispatch]);
  
  return (
    <div className="">
      <Router>
        <AppRoutes />
      </Router>
    </div>
  );
}

export default App;
