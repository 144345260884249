import React from "react";
import LoanCard from "./Story";
import Story from "./Story";

const data = [
  {
    title:
      "“I can't believe my credit score has improved by 90 points after using Score Badhao! I was struggling with loan approvals before, but now I get loan easily. Thank you for this amazing service”",
    name: "Rajesh Kumar",
    city: "Kanpur",
    rating: 5,
  },
  {
    title:
      "“Score Badhao ne mere credit score ke liye kamaal ka kaam kiya. Shuruaat mein main shak kar rahi thi, lekin kuch mahino mein maine results dekhe. Mera score badh gaya hai, aur ab mujhe ab home loan mil gaya hai”",
    name: "Sneha Rao",
    city: "Patna",
    rating: 5,
  },
  {
    title:
      "“Score Badhao team ne meri credit report samjhane mein madad ki aur mere score ko sudharne ke actionable steps diye. Aaj mera credit score healthy hai aur mujhe finally credit card approval mil gaya”",
    name: "Deepak Makkar",
    city: "Kanpur",
    rating: 5,
  },
  {
    title:
      "“I had no idea how much my poor credit score was affecting my financial opportunities. Thanks to Score Badhao, I now understand credit better and my score has gone up by 150 points in just a few months!”",
    name: "Anil Singh",
    city: "Bangalore",
    rating: 5,
  },
];

function CutomerStories() {
  return (
    <div className="my-4 md:w-full bg-blueV1 rounded-2xl">
      <div className="p-3 text-black p b-2 font-700 text-17">
        What our customers have to say{" "}
      </div>
      {data && data.length > 0 ? (
        <>
          <div
            className={`flex overflow-auto hide-scroll-bar gap-3.5 py-5 pt-3 px-4 banners-content`}
          >
            {data?.map((value, vindex) => (
              <div
                style={{ minWidth: "210px", border: "2px solid #C7DCFB" }}
                className="p-4 font-bold text-white rounded-2xl bg-white"
              >
                <Story data={value} />
              </div>
            ))}
          </div>
        </>
      ) : null}
      <div className="p-3 pt-0 pb-2 text-black font-400 text-17">
        Trusted by 1000+ Customers
      </div>
    </div>
  );
}

export default CutomerStories;
