import React, { useEffect } from "react";
// import CibilScoreComponent from "../Components/CIbilScoreText";
// import LandingBG from "./../Images/landingNew.webp";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { landingContent } from "./landing";
import PaisaWapas from "./../Images/paiseWaps.webp";
// import { useSelector } from "react-redux";
// // import FooterLinks from "./FooterLinks";
// import CutomerStories from "../Components/CustomerStories/CustomerStories";
import ScoreTech from "../Components/ScoreTech/ScoreTech";
// import Vector from "./../Images/Vector.png";
import LogoNew from "./../Images/logoNew.png";
import LandingMain from "./../Images/landingMain.png";
import FooterLinksV2 from "./FooterLinksV2";
import CutomerStories from "../Components/CustomerStories/CustomerStories";

const { NODE_ENV } = process.env;
const isProd = NODE_ENV === "production";
const BASE_URL = process.env.REACT_APP_API_URL;

const highlightText = (text, wordsToHighlight) => {
  // Split the sentence by spaces
  const parts = text.split(" ");

  return parts?.map((part, index) => {
    if (wordsToHighlight?.includes(part)) {
      return (
        <span key={index} className="font-bold">
          {part}{" "}
        </span>
      );
    }
    return <span key={index}>{part} </span>;
  });
};

export const HomePageV2 = () => {
  const { lang } = useParams();
  const currentContent = landingContent[lang];

  // useEffect(() => {
  //   if (window.fbq) {
  //     window.fbq("track", "Lead");
  //   }
  // }, []);

  useEffect(() => {
    try {
      const body = document.body;
      body.style.background = "#e1eeffb2";
    } catch (e) {}
  }, []);

  const handleProceed = () => {
    // Track the button click event in Google Analytics
    if (window.gtag) {
      window.gtag("event", "click", {
        event_category: "CTA",
        event_label: "Proceed Button Clicked",
        value: "1",
      });
    }
    try {
      if (window.fbq) {
        window.fbq("track", "Lead");
      }
    } catch (e) {
      console.log(e);
    }
    // Proceed to the login page
    // window.open(`https://rzp.io/rzp/lnqDliG`, "_self");
    window.open(`https://app.scorebadhao.com/user`, "_self");
  };

  if (!currentContent) {
    return <Navigate to="/en" />; // Redirect to '/en' if the route doesn't match
  }

  return (
    <>
      <div className="flex flex-col h-full gap-2 p-4 pb-2 md:relative pb-14 bg-white md:mx-15pe md:mt-10 md:rounded-2xl md:bg-white md:justify-center md:items-center pt-[80px] ">
        <header className="fixed top-0 left-0 w-full bg-white p-3 z-100">
          <img
            style={{ width: "160px", height: "51px" }}
            src={LogoNew}
            alt=""
          />
        </header>
        {/* <CibilScoreComponent heading={currentContent?.header} /> */}
        <div className="relative flex justify-center md:mb-4">
          <img
            className="md:w-50pe"
            // style={{ minWidth: "130px", minHeight: "100px" }}
            src={LandingMain}
            alt="landing"
          />
        </div>
        <div className="flex justify-between items-center">
          <h2 className="p-2 leading-7 text-white po:w-1/2 font-700 font-22 md:text-center">
            {currentContent?.plan_title.map((segment, index) => {
              if (segment?.highlight) {
                return (
                  <span key={index} className="text-customGold">
                    {segment?.highlight}
                  </span>
                );
              }
              return (
                <span className="text-black" key={index}>
                  {segment.text}
                </span>
              );
            })}
          </h2>
          <img
            className=""
            style={{ width: "130px", height: "130px" }}
            src={PaisaWapas}
          />
        </div>

        <ul className="space-y-4">
          {currentContent?.features?.map((feature, index) => (
            <li
              key={index}
              className="flex items-center p-2 space-x-2 text-white rounded-lg bg-blueV1"
            >
              <div
                style={{ minWidth: "48px", height: "48px" }}
                className="flex items-center justify-center p-2 bg-blueV2 rounded-lg"
              >
                <img src={feature?.icon} />
              </div>
              <div>
                {/* <h3 className="text-lg font-semibold">{feature?.title}</h3> */}
                <p className="text-black text-14 font-400">
                  {highlightText(
                    feature?.description,
                    feature?.wordsToHighlight
                  )}{" "}
                </p>
              </div>
            </li>
          ))}
        </ul>
        <footer className="fixed bg-btnV1 font-semibold cursor-pointer w-full bottom-0 left-0 p-3 text-17">
          <div
            className="flex items-center justify-center p-2 gradient-btn rounded-3xl"
            onClick={handleProceed}
          >
            <div></div>
            {currentContent?.cta}
          </div>
        </footer>

        {/* <ScoreTech /> */}
        <CutomerStories />
      </div>
      <div className="pb-16 md:mx-15pe">
        <FooterLinksV2 />
      </div>
    </>
  );
};
