import React from 'react';

const CustomerRating = ({ rating, totalStars = 5 }) => {
  return (
    <div className="flex space-x-1">
      {[...Array(totalStars)].map((_, index) => {
        const starValue = index + 1;
        return (
          <svg
            key={starValue}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill={starValue <= rating ? '#ffe49d' : 'black'} // Highlight stars based on rating
            className="w-6 h-6"
          >
            <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
          </svg>
        );
      })}
    </div>
  );
};

export default CustomerRating;
