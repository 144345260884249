// src/api/userApi.js

export const fetchUserFromAPI = async () => {
    const response = await fetch('https://dummyjson.com/todos');
    console.log('check1234');

    if (!response.ok) {
      throw new Error("Failed to fetch user data");
    }
    console.log('check1234');
    const data = await response.json();
    return data;
  };
  
  