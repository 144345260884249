import React from "react";
import { toCaptionCase } from "../../utils/methods";
import moment from "moment";
import CustomerRating from "../Ratings.jsx/Ratings";

function Story({ data }) {
  return (
    <div className="text-black">
      <div className="font-700 text-12">{toCaptionCase(data?.title)}</div>
      <div className="font-semibold text-10 opacity-60">
        {data?.name} | {data.city || "Bangalore"}
      </div>
      <CustomerRating rating={data?.rating} />
    </div>
  );
}

export default Story;
