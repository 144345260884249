// src/reducers/userReducer.js
import {
    FETCH_USER,
    FETCH_USER_SUCCESS,
    FETCH_USER_FAILURE,
  } from "./../actionTypes/common";
  
  const initialState = {
    loading: false,
    user: null,
    error: null,
  };
  
  const commonReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_USER:
        return { ...state, loading: true };
      case FETCH_USER_SUCCESS:
        console.log('action',action)
        return { ...state, loading: false, user: action.payload };
      case FETCH_USER_FAILURE:
        return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export default commonReducer;
  