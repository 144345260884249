import { createAction } from "redux-actions";
import {
  FETCH_USER,
  FETCH_USER_FAILURE,
  FETCH_USER_SUCCESS,
} from "../actionTypes/common";

export const fetchUser = createAction(FETCH_USER,(payload)=>{return payload});

export const fetchUserSuccess = createAction(FETCH_USER_SUCCESS);

export const fetchUserFailure = createAction(FETCH_USER_FAILURE);
