// src/Routes.js
import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { HomePageV2 } from "./containers/HomePageV2";
import { HomePage } from "./containers/HomePage";

const AppRoutes = () => {
  const location = useLocation();

  useEffect(() => {
    // Track page views on route change
    if (window.gtag) {
      window.gtag("config", "G-JQ17N2B4P6", {
        page_path: location.pathname,
      });
    }
  }, [location]);

  return (
    <Routes>
      <Route exact path="/d" element={<HomePage />} />
      <Route exact path="/" element={<HomePageV2 />} />
      <Route path="/:lang" element={<HomePageV2 />} />
      <Route path="/d/:lang" element={<HomePage />} />
      {/* <Route path="*" element={<NotFound />} /> */}
    </Routes>
  );
};

export default AppRoutes;
