export const toCaptionCase = (str = "", charsToReplace) => {
  const regex = new RegExp(
    ["_", "-", ...(charsToReplace || [])].join("|"),
    "g"
  );
  return typeof str === "string"
    ? str
        .replace(regex, " ")
        .split(" ")
        .map(
          (string) =>
            string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
        )
        .join(" ")
    : "";
};

export const toCurrencyWithoutSpace = (
  val,
  minimumFractionDigits = 0,
  unicode = "20B9",
  printMode
) => {
  const unicodeChar = printMode ? "Rs. " : JSON.parse(`["\\u${unicode}"]`)[0];
  if (!val || val === 0) {
    return `${unicodeChar}0`;
  }
  if (minimumFractionDigits === 0) {
    return `${unicodeChar}${Math.floor(val)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`;
  }
  const parsedString =
    (val &&
      parseFloat(val) &&
      parseFloat(val)
        .toFixed(minimumFractionDigits)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")) ||
    "";
  return `${unicodeChar}${parsedString}`;
};
