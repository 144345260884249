import React from "react";
import ScoreLogo from "./../Images/ScoreIcon.png";
import Insta from "./../Images/insta.svg";
import FaceBook from "./../Images/facebook.svg";
import LinkedIn from "./../Images/linkedIn.svg";
import Youtube from "./../Images/youtube.svg";

function FooterLinks() {
  return (
    <div className="flex items-start justify-between w-full p-2 text-white md:w-87p bg-badhao-clr">
      <div className="flex flex-col w-full gap-4 font-semibold text-12">
        <a href="https://app.scorebadhao.com/terms-and-conditions">
          Terms & conditions
        </a>
        <a href="https://app.scorebadhao.com/privacy-policy">Privacy Policy</a>
        <a href="https://app.scorebadhao.com/refund-policy">Refund Policy</a>
        <a href="https://app.scorebadhao.com/contact-us">Contact us</a>
      </div>
      <div className="flex flex-col items-end justify-end gap-3">
        <img
          style={{ width: "175px", height: "40px" }}
          src={ScoreLogo}
          alt="score"
        />
        <div className="flex items-center justify-between gap-px">
          <a href="https://www.instagram.com/score_badhao?igsh=a2R2MTIwamx4aHJ5&utm_source=qr">
            <img src={Insta} alt="insta" />
          </a>
          <a href="">
            <img src={FaceBook} alt="fb" />
          </a>
          <a href="https://www.linkedin.com/company/score-badhao/">
            <img src={LinkedIn} alt="lnkdn" />
          </a>
          <a href="">
            <img src={Youtube} alt="yt" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default FooterLinks;
